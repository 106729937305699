.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* start kong */

.w-nav {
  position: relative;
  z-index: 1000;
}

.navbar {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: rgba(22, 22, 22, 0.8);
}

.container-1.nav {
  max-width: 90vw;
}
.container-1 {
  max-width: 1200px;
}
.horizontal-betw {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 4.48em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
.w-nav-menu {
  position: relative;
  float: right;
}
.nav-menu {
  position: absolute;
}
a {
  background-color: transparent;
}
.w-nav-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  color: #222222;
  padding: 20px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}
.nav-link {
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  color: #fff;
  font-size: 1.25em;
}
.socials-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.socials-wrap.nav {
  margin-left: 2.6em;
}
.socials-wrap.nav.mob {
  display: none;
}
.w-inline-block {
  max-width: 100%;
  display: inline-block;
}
.socials-link {
  margin-right: 1em;
}
img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}
.image-66 {
  width: 2.43em;
}
.image-66.nav {
  width: auto;
  height: 20px;
}
.w-button {
  display: inline-block;
  padding: 9px 15px;
  background-color: #3898ec;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
}
.button {
  margin-top: 1em;
  padding: 1.04em 3.47em;
  border-style: solid;
  border-width: 2px;
  border-color: #fffc00;
  border-radius: 100px;
  background-color: #ffd;
  -webkit-transition: box-shadow 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: box-shadow 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  font-family: "Clash Display", sans-serif;
  color: #161616;
  font-size: 1.46em;
  font-weight: 700;
}

.mint-button {
  padding: 0.5em 2em !important;
  cursor: pointer;
}

.button.nav {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  margin-top: 0.3em;
  padding: 0.74em 2.57em;
  background-color: transparent;
  -webkit-transition: color 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
    background-color 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
    box-shadow 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: color 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
    background-color 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
    box-shadow 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  color: #fff;
  font-size: 1.6em;
}

.button._2nd {
  background-color: transparent;
  -webkit-transition: color 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
    background-color 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
    box-shadow 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: color 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
    background-color 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
    box-shadow 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  color: #fff;
}
.button._2nd.nav-button-mnobile {
  display: none;
}
.w-nav-button {
  position: relative;
  float: right;
  padding: 18px;
  font-size: 24px;
  display: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.w-nav-button {
  position: relative;
  float: right;
  padding: 18px;
  font-size: 24px;
  display: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.w-nav:before,
.w-nav:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}

[class^="w-icon-"],
[class*=" w-icon-"] {
  font-family: "webflow-icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
img {
  border: 0;
}
img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}
.image {
  width: 4.86em;
}
a:active,
a:hover {
  outline: 0;
}
.nav-link:hover {
  opacity: 0.6;
}
.button.nav:hover {
  background-color: #ffd;
  color: #161616;
}
.button:hover {
  box-shadow: 0 0 30px 0 #fffc00;
}

.hero-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url(../public/images/61f25d51fa78fa25be870db5_hero-bg.png);
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin-bottom: 10px;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

h1 {
  font-size: 38px;
  line-height: 44px;
  margin-top: 20px;
}

.h1 {
  font-family: "Clash Display", sans-serif;
  color: #161616;
  font-size: 4.44em;
  line-height: 120%;
  font-weight: 700;
}

.mint-live {
  color: #fff;
  -webkit-filter: drop-shadow(0 0 15px rgba(255, 251, 0, 0.760784));
  filter: drop-shadow(0 0 15px rgba(255, 251, 0, 0.760784));
  text-shadow: 0 0 10px rgb(255 251 0 / 79%);
}

.vert-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.container-1 {
  max-width: 1200px;
}
.container-1.relative {
  position: relative;
}
.container-1.relative.z-5 {
  z-index: 5;
  max-width: 1200px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.container-1.relative.z-5.hero {
  max-width: 90vw;
}

.mint-wrapper {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
}
.vert-left.fixed-w-650 {
  width: 45.14em;
  padding-right: 13.5em;
  padding-left: 1.4em;
}

p {
  margin-top: 0;
  margin-bottom: 10px;
}
.p1 {
  margin-bottom: 30px;
  color: #fff;
  font-size: 1.39em;
  line-height: 120%;
}

.mint,
.mint-error {
  margin-top: 20px;
}
.mint-limited-wrapper {
  display: flex;
  margin-bottom: 20px;
}
h3 {
  font-size: 24px;
  line-height: 30px;
  margin-top: 20px;
}

.h3 {
  font-family: "Clash Display", sans-serif;
  color: #161616;
  font-size: 2.5em;
  line-height: 120%;
  text-align: center;
}
.mint-limited {
  margin-right: 15px !important;
}
.mint-limited-wrapper h3 {
  color: #fff;
  text-align: start;
}
.mint-quantity-wrapper,
.total-price {
  display: flex;
  align-items: center;
}
.ticket-quantity {
  height: 52px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  width: 150px;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button,
html input[type="button"],
input[type="reset"] {
  border: 0;
  cursor: pointer;
  -webkit-appearance: button;
}
.ticket-quantity-minus,
.ticket-quantity-plus {
  height: 100%;
  width: 30%;
  background: transparent;
  border: none;
  font-size: 22px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}
.ticket-quantity-minus {
  border-top-left-radius: 26px;
  border-bottom-left-radius: 26px;
  border-left: 2px solid #fffc00;
  border-bottom: 2px solid #fffc00;
  border-top: 2px solid #fffc00;
  cursor: pointer;
  padding-bottom: 3px;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
input {
  line-height: normal;
}

.ticket-quantity-input {
  color: #fff;
  text-align: center;
  font-weight: 700;
  height: 100%;
  width: 40%;
  outline: none;
  font-size: 22px;
  color: inherit;
  background: transparent;
  -webkit-appearance: textfield !important;
  appearance: textfield !important;
  border: 2px solid #fffc00;
  border-right: none;
  border-left: none;
}

.ticket-quantity-plus {
  border-top-right-radius: 26px;
  border-bottom-right-radius: 26px;
  border-right: 2px solid #fffc00;
  border-bottom: 2px solid #fffc00;
  border-top: 2px solid #fffc00;
  cursor: pointer;
  padding-bottom: 5px;
}
.mint-quantity-wrapper,
.total-price {
  display: flex;
  align-items: center;
}

.total-price {
  text-transform: uppercase;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0 0 0 20px;
  height: 36px;
  font-family: "Clash Display", sans-serif;
}

.mint,
.mint-error {
  margin-top: 20px;
}
.mint-error {
  display: block;
  color: red;
  font-family: "Clash Display", sans-serif;
  letter-spacing: 0.11em;
  height: 20px;
}

.hero-visual {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  width: 53.47em;
}

.button._2nd:hover {
  background-color: #ffd;
  color: #161616;
}

.w-nav-overlay {
  position: absolute;
  overflow: hidden;
  display: none;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
}

.jungle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 6.94em;
  padding-bottom: 6.94em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.p2 {
  color: #161616;
  font-size: 1.25em;
}
.p2.p-center {
  font-size: 1.1em;
  line-height: 120%;
  text-align: center;
}
.text-span {
  font-family: "Clash Display", sans-serif;
}

.w-background-video {
  position: relative;
  overflow: hidden;
  height: 500px;
  color: white;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

.w-background-video {
  position: relative;
  overflow: hidden;
  height: 500px;
  color: white;
}

.w-background-video > video {
  background-size: cover;
  background-position: 50% 50%;
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  right: -100%;
  bottom: -100%;
  top: -100%;
  left: -100%;
  object-fit: cover;
  z-index: -100;
}

.bg-video {
  width: 100%;
  height: 100vh;
}

.about {
  padding-top: 6.94em;
  padding-bottom: 6.94em;
  background-color: #161616;
}

.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}

.w-container:before,
.w-container:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}
.w-container:after {
  clear: both;
}

.container-2 {
  max-width: 66vw;
}

.vert-cent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

h2 {
  font-size: 32px;
  line-height: 36px;
  margin-top: 20px;
}

.h2 {
  font-family: "Clash Display", sans-serif;
  color: #fff;
  font-size: 3.33em;
  line-height: 120%;
  font-weight: 700;
}
.text-span-2 {
  color: #fffc00;
}

.p2.p-white {
  color: #fff;
  line-height: 120%;
}

.p2.p-center.p-white {
  color: #fff;
  font-size: 1.2em;
}

.p2.p-center._w-570 {
  width: 32em;
}

.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.grid {
  margin-top: 2.8em;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  grid-column-gap: 4.86em;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.artists-wrap {
  padding-top: 6.94em;
  padding-bottom: 6.94em;
  background-color: #fffc00;
}

.container-1 {
  max-width: 1200px;
}

.artist {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.artist-img {
  width: 100%;
}
.imdb-logo {
  margin-top: 1em;
}
.imdb-img {
  width: 6.94em;
}

.club {
  position: relative;
  padding-top: 8.33em;
  padding-bottom: 8.33em;
  background-color: #000;
}

.image-2 {
  position: absolute;
  left: 0%;
  top: auto;
  right: auto;
  bottom: 0%;
  width: 44%;
}

.horizontal-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.vert-left._w-50perc {
  width: 50%;
}

.roadmap-wrap {
  overflow: hidden;
  padding-top: 6.94em;
  padding-bottom: 6.94em;
  padding-left: 6.2em;
  background-color: #161616;
}

.w-slider {
  position: relative;
  height: 300px;
  text-align: center;
  background: #dddddd;
  clear: both;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

.slider {
  height: 52em;
  padding-top: 8em;
  background-color: transparent;
}

.h1.h-white {
  color: #fff;
}
.h1.h-white.h-absolute {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  margin-top: 0px;
}
.w-slider-mask {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
  left: 0;
  right: 0;
  height: 100%;
  white-space: nowrap;
}
.mask {
  overflow: visible;
  width: 32.92em;
}

.w-slider-arrow-left,
.w-slider-arrow-right {
  position: absolute;
  width: 80px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  cursor: pointer;
  overflow: hidden;
  color: white;
  font-size: 40px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.w-slider-arrow-left {
  z-index: 3;
  right: auto;
}

.left-arrow {
  left: auto;
  top: 0%;
  right: 14%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #fffc00;
  border-radius: 50%;
}
.image-3 {
  width: 10px;
}

.w-slider-arrow-right {
  z-index: 4;
  left: auto;
}

.right-arrow {
  left: auto;
  top: 0%;
  right: 7%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #fffc00;
  border-radius: 50%;
}

.w-slider-nav {
  position: absolute;
  z-index: 2;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  padding-top: 10px;
  height: 40px;
  text-align: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}
.w-slider-dot {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  margin: 0 3px 0.5em;
  transition: background-color 100ms, color 100ms;
}
.w-slider-nav.w-round > div {
  border-radius: 100%;
}

.w-slide {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
  white-space: normal;
  text-align: left;
}

.slide {
  width: 100%;
  margin-right: 1.39em;
  background-color: #000;
}

.slide-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 2.08em;
  padding-right: 2.08em;
  padding-left: 2.08em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.h3.h-yellow {
  margin-top: 0px;
  color: #fffc00;
}
.h3.h-center {
  text-align: center;
}
.h3.h-center.h-white {
  color: #fff;
}
.image-3._2 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.w-slider-dot.w-active {
  background-color: #fff;
}
.slide-nav {
  display: none;
}

.festival {
  position: relative;
  padding-top: 6.94em;
  padding-bottom: 6.94em;
  background-color: #000;
}

.image-4 {
  position: absolute;
  left: 0%;
  top: auto;
  right: auto;
  bottom: 0%;
  width: 30%;
}
.image-5 {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  width: 30%;
}
.h1.h-white {
  color: #fff;
}

.h1.h-center {
  text-align: center;
}

.h1.h-white.h-center {
  text-align: center;
}

.text-span-11 {
  color: #fffc00;
}

.tb-24 {
  width: 67%;
  color: #fff;
  font-size: 1.67em;
  line-height: 120%;
  text-align: center;
}

.text-span-12 {
  font-family: "Clash Display", sans-serif;
  text-transform: uppercase;
}

.team {
  padding-top: 6.94em;
  padding-bottom: 6.94em;
}

.team-member {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 220px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.grid-2 {
  margin-top: 3em;
  grid-column-gap: 5em;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}
.team-img {
  width: 150px;
}

.grid-2._2 {
  margin-top: 3em;
  grid-column-gap: 5em;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
h4 {
  font-size: 18px;
  line-height: 24px;
  margin-top: 10px;
}

.team.no-top-padding {
  padding-top: 0em;
}

.grid-2._3 {
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}

.faq-div {
  padding-top: 6.94em;
  padding-bottom: 6.94em;
  background-color: #161616;
}

.faq-wrap {
  width: 100%;
  margin-top: 5.9em;
}

.w-dropdown {
  display: inline-block;
  position: relative;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  z-index: 900;
}

.dropdown {
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 0.63em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-style: none;
  border-width: 2px;
  border-color: #fff;
  border-radius: 0px;
  background-color: #000;
}

.w-dropdown-btn,
.w-dropdown-toggle,
.w-dropdown-link {
  position: relative;
  vertical-align: top;
  text-decoration: none;
  color: #222222;
  padding: 20px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
}

.w-dropdown-toggle {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  cursor: pointer;
  padding-right: 40px;
}

.dropdown-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 1.97em 1.46em 1.94em 2.36em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000;
}

.image-65 {
  position: absolute;
  left: auto;
  top: 36%;
  right: 2%;
  bottom: 0%;
  width: 1.4em;
}

.h3.all-caps {
  color: #fff;
  font-size: 1.7em;
  font-weight: 700;
  text-transform: uppercase;
}

.h3.all-caps.desk {
  font-size: 1.7em;
  text-align: left;
}

.h3.all-caps.mob {
  display: none;
  text-align: left;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

.w-dropdown-list {
  position: absolute;
  background: #dddddd;
  display: none;
  min-width: 100%;
}

.dropdown-list {
  position: relative;
  display: block;
  width: 100%;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  background-color: #000;
}

.p2-2 {
  margin-bottom: 0px;
  color: #192024;
  font-size: 0.94em;
  line-height: 150%;
  font-weight: 400;
}

.p2-2.opacity-80 {
  padding: 0em;
  color: rgba(25, 32, 36, 0.8);
}

.p2-2.opacity-80.padding {
  width: 100%;
  padding: 0.36em 1.46em 1.46em;
  color: #fff;
  font-size: 1.25em;
  font-weight: 400;
}

.footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 3.47em;
  padding-bottom: 3.47em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000;
}

.w-nav-brand {
  position: relative;
  float: left;
  text-decoration: none;
  color: #333333;
}

.brand {
  position: relative;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
}

.brand.footer-brand {
  position: static;
}

.socials-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.socials-link {
  margin-right: 1em;
}

.image-66 {
  width: 2.43em;
}
/* end kong */

/* kong mobile */
[data-nav-menu-open] {
  display: block !important;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #C8C8C8;
  text-align: center;
  overflow: visible;
  min-width: 200px;
}

[class^="w-icon-"],
[class*=" w-icon-"] {
  font-family: "webflow-icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.w-icon-nav-menu:before {
  content: "\e602";
}

@media screen and (max-width: 991px) {

  .nav-link {
    padding-left: 0px;
    font-size: 18px;  
  }

  .h1 {
    font-size: 40px;
  }

  .navbar {
    padding-top: 14px;
    padding-bottom: 14px;
  }

  .w-container {
    max-width: 728px;
  }

  .horizontal-betw {
    height: auto;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .div-block {
    margin-top: 6px;
  }
  .brand {
    position: relative;
  }
  .image {
    width: 50px;
  }
  .socials-wrap.nav {
    display: none;
  }
  .w-nav[data-collapse="medium"] .w-nav-menu {
    display: none;
  }

  .container-1 {
    max-width: 92vw;
  }
  .button {
    margin-top: 10px;
    padding: 14px 40px;
    font-size: 14px;
  }
  .w-nav[data-collapse="medium"] .w-nav-button {
    display: block;
  }
  .menu-button {
    color: #fff;
  }
  .button.nav {
    display: none;
  }
  .h1 {
    font-size: 40px;
  }
  .hero-wrap {
    height: 60vh;
  }
  .vert-left.fixed-w-650 {
    width: 350px;
  }
  .p1 {
    font-size: 16px;
  }
  .h3 {
    font-size: 22px;
  }
  .p2.p-center {
    font-size: 14px;
  }

  .jungle {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .bg-video {
    height: 60vh;
  }
  .about {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .h2 {
    font-size: 30px;
  }
  .p2.p-white {
    font-size: 14px;
  }
  .p2.p-center {
    font-size: 14px;
  }
  .h3 {
    font-size: 22px;
  }
  .imdb-img {
    width: 60px;
  }
  .vert-left._w-50perc {
    width: 62%;
  }
  .club {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .image-2 {
    width: 40%;
  }
  .roadmap-wrap {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 40px;
  }
  .mask {
    width: 350px;
}
.left-arrow {
  right: 16%;
  width: 40px;
  height: 40px;
}
.right-arrow {
  width: 40px;
  height: 40px;
}
.h3 {
  font-size: 22px;
}
.festival {
  padding-top: 40px;
  padding-bottom: 40px;
}
.image-4 {
  width: 26%;
}
.image-5 {
  width: 26%;
}
.tb-24 {
  font-size: 16px;
}
.team-member {
  width: 100px;
}
.image-65 {
  display: none;
}
.h3.all-caps.desk {
  font-size: 18px;
}
.p2-2.opacity-80.padding {
  font-size: 14px;
}
.h3.all-caps {
  font-size: 18px;
}
.socials-link {
  margin-right: 20px;
}
.image-66 {
  width: 26px;
}
.socials-wrap.nav.mob {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 0em;
}
  /* end 991 */
}

@media screen and (max-width: 767px) {
  .navbar {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .container-1 {
    max-width: none;
  }
  .w-nav-brand {
    padding-left: 10px;
  }
  .nav-menu {
    padding-right: 8px;
    padding-left: 20px;
  }
  .bg-video {
    height: 50vh;
  }
  .container-2 {
    max-width: 86vw;
  }
  .grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }
  .artist {
    margin-bottom: 30px;
  }
  .artist-img {
    width: 50%;
  }
  .h3 {
    text-align: center;
  }
  .vert-left._w-50perc {
    width: 100%;
  }
  .image-2 {
    display: none;
  }
  .mask {
    bottom: -40px;
    height: 480px;
}
.h3 {
  text-align: center;
}
.image-4 {
  display: none;
}
.image-5 {
  display: none;
}
.tb-24 {
  width: 100%;
}
.grid-2 {
  grid-column-gap: 5em;
}
.grid-2._2 {
  grid-column-gap: 5em;
}
.team-member {
  width: 100px;
}
.dropdown {
  margin-bottom: 20px;
}
.h3.all-caps {
  text-align: left;
}
/* end 767 */
}

@media screen and (max-width: 479px) {
  .nav-link {
    padding-left: 0px;
    color: #fff;
    font-size: 20px;
  }
  .h1 {
    text-align: center;
  }
  .w-container {
    max-width: none;
  }
  .horizontal-betw.nav {
    width: 100%;
  }
  .nav-menu {
    padding: 26px 16px;
    background-color: #161616;
  }
  .w-container {
    max-width: none;
  }

  .container-1 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .socials-wrap {
    margin-bottom: 4em;
  }
  .button._2nd.nav-button-mnobile {
    display: block;
  }
  .hero-wrap {
    overflow: hidden;
    height: 100vh;
    /* padding-top: 110px; */
    -webkit-box-align: start;
    -webkit-align-items: center;
    -ms-flex-align: start;
    align-items: center;
  }
  .vert-left.fixed-w-650 {
    width: auto;
  }
  .hero-visual {
    width: 70%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .jungle {
    padding-right: 36px;
    padding-left: 36px;
  }
  .bg-video {
    width: auto;
    height: 20vh;
  }
  .container-2 {
    max-width: 88vw;
  }
  .p2.p-center.p-white {
    font-size: 14px;
  }
  .vert-cent.artist-phone {
    width: 300px;
  }
  .p2.p-center._w-570 {
    width: auto;
  }

  .horizontal-div {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .vert-left._w-50perc {
    width: 80%;
  }
  .club {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .image-2 {
    position: static;
    display: block;
    width: 100%;
  }
  .h1.h-white.h-absolute {
    width: 150px;
    font-size: 25px;
    text-align: left;
  }
  .roadmap-wrap {
    padding-left: 14px;
  }
  .slider {
    height: 700px;
    padding-top: 20px;
  }
  .h1.h-white.h-absolute {
    width: 150px;
    font-size: 25px;
    text-align: left;
}
.mask {
  bottom: -84px;
  width: 270px;
  height: 600px;
}
.left-arrow {
  right: 24%;
}
.slide {
  margin-right: 20px;
}
.slide-content {
  padding: 10px;
}
.h1.h-center {
  font-size: 30px;
}
.h1.h-center {
  font-size: 30px;
}
.grid-2 {
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}
.grid-2._2 {
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}
#w-node-_09b55641-06e4-bb40-263c-06b5bde83e1e-fb9a52d9 {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
}
#w-node-_6b212cf3-485c-0459-5b20-d5aceae1bea0-fb9a52d9 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-column-align: center;
  justify-self: center;
}
#w-node-dd80a94d-7abb-4fe8-80c7-f5f564951e33-fb9a52d9 {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column-align: center;
  justify-self: center;
}
.team-member {
  width: 130px;
}
.faq-wrap {
  width: 90%;
}
.dropdown {
  margin-bottom: 1.53em;
}
.dropdown-toggle {
  display: block;
  padding: 12px 10px;
}
.image-65 {
  display: block;
  width: 3em;
}
.h3.all-caps.desk {
  display: none;
}
.h3.all-caps.mob {
  display: block;
  font-size: 20px;
}
.p2-2.opacity-80.padding {
  padding: 10px;
}
.h3.all-caps {
  font-size: 20px;
}
.brand.footer-brand {
  margin-bottom: 2em;
}
.socials-wrap {
  margin-bottom: 4em;
}
/* end 479 */
}

@media screen and (max-width: 485px) {
  #mint-home .vert-left .mint-live {
    margin-bottom: 10px;
  }

  #mint-home .vert-left {
    align-items: center;
    text-align: center;
  }
  #mint-home .vert-left .mint-limited-wrapper {
    flex-direction: column;
    align-items: center;
  }
  #mint-home .vert-left .mint-limited-wrapper .mint-limited {
    margin: 0 !important;
  }
  #mint-home .vert-left .mint-quantity-wrapper {
    flex-direction: column;
    align-items: center;
  }
  #mint-home .vert-left .mint-quantity-wrapper .total-price {
    margin: 10px 0 0;
  }
  #mint-home .vert-left .mint-button {
    width: 100%;
    font-size: 1.8rem;
    padding: 15px 30px !important;
    line-height: 1.8rem;
  }
  #mint-home .vert-left .mint-error {
    font-size: 1rem;
  }

  #mint-home .vert-left .mint-button {
    width: 100%;
    font-size: 1.8rem;
    padding: 15px 30px!important;
    line-height: 1.8rem;
}
  /* end 485 */
}

@media screen and (max-width: 767.98px) {
  .ticket-quantity {
    top: unset;
    bottom: 30px;
    right: 0;
  }
}

.mint-live {
  color: #fff;
  -webkit-filter: drop-shadow(0 0 15px rgba(255, 251, 0, 0.760784));
  filter: drop-shadow(0 0 15px rgba(255, 251, 0, 0.760784));
  text-shadow: 0 0 10px rgb(255 251 0 / 79%);
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

#mint-home h1,
#mint-home h2,
#mint-home h3,
#mint-home p {
  margin: 0;
  padding: 0;
}
.text-span-5 {
  font-family: 'Clash Display', sans-serif;
  text-transform: uppercase;
}
.h2.h-center {
  text-align: center;
}
.mint, .mint-error {
  margin-top: 20px;
}

.faq-wrap .dropdown:hover .all-caps{
  color : rgb(255, 252, 0) !important;
}
/* end kong mobile */

.w-nav-overlay [data-nav-menu-open] {
  top: 0;
}

.w--nav-link-open {
  display: block;
  position: relative;
}